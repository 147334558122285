import Vue from "vue";
import VueRouter from "vue-router";
const HomePage = () => import("./routes/HomePage.vue");
const ResultPage = () => import("./routes/ResultPage.vue");
const SuccessPage = () => import("./routes/SuccessPage.vue");
//import Test from './routes/Test.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: "/", name: "home", component: HomePage },
    { path: "/result", name: "result", component: ResultPage },
    { path: "/success", name: "leadSuccess", component: SuccessPage },
  ],

  mode: "history",
});

export default router;
