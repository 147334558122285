import Vue from "vue";
import Router from "./routes.js";
import VueResource from "vue-resource";
import App from "./App.vue";

require("bootstrap/dist/js/bootstrap.min.js");
require("lightbox2/dist/css/lightbox.css");
const lightbox = require("lightbox2/dist/js/lightbox.min.js");

lightbox.option({
  positionFromTop: 30,
  maxWidth: 1200,
  showImageNumberLabel: false,
});

Vue.config.productionTip = false;

Vue.use(VueResource);

new Vue({
  render: (h) => h(App),
  router: Router,
}).$mount("#app");
