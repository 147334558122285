<template>
  <div>
    <pg-header :idIcon="menu.icon" :idMenu="menu.id"></pg-header>

    <router-view></router-view>

    <pg-footer></pg-footer>
    <up-btn></up-btn>
  </div>
</template>

<script>
require("bootstrap");
import PgHeader from "./components/header/Header.vue";
import PgFooter from "./components/footer/Footer.vue";
import UpBtn from "./components/buttons/UpButton.vue";

export default {
  name: "app",

  components: { PgHeader, PgFooter, UpBtn },

  data() {
    return {
      menu: {
        id: "menu",
        icon: "menu-icon",
      },
    };
  },
};
</script>

<style>
@import url("assets/css/app.css");
</style>
